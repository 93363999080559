/**
 * Compares a route to a route pattern to determine if they match.
 * Supports dynamic segments in routes, where a segment starting with `:` acts as a wildcard.
 *
 * @param {string} routePattern - The base route pattern, which may include dynamic segments (e.g., `/user/:id/profile`).
 * @param {string} route - The route to compare against the base pattern (e.g., `/user/123/profile`).
 * @returns {boolean} - Returns `true` if the routes match, considering dynamic segments; otherwise, `false`.
 *
 * @example
 * // Matching static routes
 * compareRoutes('/home', '/home'); // true
 *
 * // Matching dynamic routes
 * compareRoutes('/user/:id/profile', '/user/123/profile'); // true
 *
 * // Non-matching routes
 * compareRoutes('/user/:id/profile', '/user/123/settings'); // false
 */
export function compareRoutes(routePattern: string, route: string) {
  const regex = new RegExp(`^${routePattern.replace(/:[^/]+/g, '[^/]+')}$`);

  return regex.test(route);
}
